import cn from 'clsx'
import useLogOut from 'hooks/useLogOut'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const MainContainer = ({ children, className }) => {
  const router = useRouter()
  const { logOutCallback } = useLogOut()

  useEffect(() => {
    //
    logOutCallback()
  }, [router.asPath])

  return (
    <main className={cn('h-screen', className)} id='AppMain'>
      {children}
    </main>
  )
}

export default MainContainer
