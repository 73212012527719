const MSG = {
  SUCCESS: {
    EVENT_UPDATE: 'Event updated successfully',
    ORDER_CANCELLATION: 'Order cancelled successfully',
    EVENT_DELETION: 'Event deleted successfully',
    UNPUBLISH_EVENT: 'Event unpublished successfully',
    ARCHIVE_EVENT: 'Event Archived successfully',
    VERIFY_HOST: 'Host Verification Updated successfully !',
    TICKET_ISSUED: 'Ticket issued successfully!',
    TICKET_RESERVED: 'Ticket reserved succesfully!',
    SELLER: {
      TICKET_UPD: 'Tickets Updated!',
      TICKET_GRP: 'Tickets Group Updated!',
    },
    DELETE_TICKET: {
      TITLE: 'Confirm Deletion ?',
      MESSAGE: 'Are you sure you want to Delete the ticket ?',
    },
  },
  ERROR: {
    OPERATION_FAILED: 'Something went wrong. Please Try Again!',

    GUEST: {
      ALREADY_IN_USE: 'This email is already in use. Try Forget Password to regain access.',

      SIGN_UP: {
        TERMS: 'You need to agree to our terms to use Sessami',
        PASSWORD_MIN_6: 'Please enter a password of at least 6 characters.',
      },
    },
    RESERVATION_FAILED: 'failed to reserve ticket',
    EVENT_UPDATE: 'Error updating event',
    FAIL_FETCHING_ORDERS: 'Error fetching orders',
    MISSING_PARAMS: 'Missing params. Check emailAddr, subject, and htmlBody',
    BUYER: {
      FETCH_EVENT_DETAILS: 'Error fetching event details!',
    },
    FAIL_EVENT_DELETION: 'Error on event deletion',
    FAIL_EVENT_UNPUBLISH: 'Error on unpublishing event',
    FAIL_EVENT_ARCHIVE: 'Error on archiving event',
    FAIL_HOST_VERIFICATION: 'Error on verifying host ',
    ERR_GENERATING_TICKET: 'Something went wrong while generating tickets!',
    REQUEST_OPTION_ERR: 'Request options are missing',
  },
  CONFIRM: {
    SELLER: {
      DELETE_TICKET: {
        TITLE: 'Confirm Deletion ?',
        MESSAGE: 'Are you sure you want to Delete the ticket ?',
      },
    },
    EVENT_IS_DELETED: {
      DELETED: {
        TITLE: 'Confirm Deletion',
        MESSAGE: 'Are you sure you want to Delete the event ?',
      },
      UNPUBLISH: {
        TITLE: 'Confirm Unpublish',
        MESSAGE: 'Are you sure you want to Unpublish the event ?',
      },
    },
    HOST_IS_VERIFIED: {
      VERIFY: {
        TITLE: 'Confirm Host Verification Status Update',
        MESSAGE: 'Are you sure you want to update the host verification status ?',
      },
    },
    IMAGE_DELETION: {
      DELETION: {
        TITLE: 'Confirm Deletion',
        MESSAGE: 'Are you sure to Delete the image ?',
      },
    },
  },
  INFO: {
    SELLER: {
      SELECT_AT_LEAST_A_FREETICKET_TYPE: 'Please select a free ticket type',
    },
    VERIFICATION_EMAIL_MESSAGE:
      "<br/><b>Congratulations !!!</b> on successfully completing the verification process! We're thrilled to have you as a verified host of our platform.Your commitment to security and trustworthiness is commendable, and we're excited to continue providing you with exceptional service and support. <br/><br/> Thank you for your cooperation and dedication. <br/> Welcome again!",
    REVOCATION_EMAIL_MESSAGE:
      "<br/>We regret to inform you that your verification status has been revoked. This decision was made due to failure to meet our verification criteria. We understand this may be disappointing, but we're committed to maintaining the integrity and security of our platform. <br/><br/>If you have any questions or would like to discuss this further, please don't hesitate to reach out to our support team or email us at <b>hello@sessami.co</b>. <br/><br/>Thank you for your understanding.",
    VERIFY_HOST: 'Please provide the valid reason of host ',
    DELETE_EVENT: 'Deleting leads to removal of event from the database and cannot be undone.',
    UNPUBLISH_EVENT:
      'Archiving leads to removal of event from the Marketplace only, thus stopping sale.',
  },
}

export default MSG
