import MSG from 'consts/Messages'
import ROUTES from 'consts/Routes'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { auth } from 'services/firebaseClient'
import { resetUI, resetUser, setEvents } from 'store/reducers'
import deleteCookie from 'utils/deleteCookie'

import useConfirmation from './useConfirmation'

export default function useLogOut() {
  const dispatch = useDispatch()
  const router = useRouter()

  const { showConfirmation } = useConfirmation()

  const logOutCallback = async () => {
    try {
      dispatch(resetUser())
      dispatch(setEvents([]))
      dispatch(resetUI())

      // Also delete cookie from the browser
      deleteCookie('currentUser')

      await auth.signOut()

      // Redirect user to the home page -> can be set accordingly
      router.replace(ROUTES.SIGN_IN)
    } catch (error) {
      console.log('Logout error:', error)
      // Handle error here, e.g., show an error message to the user
    }
  }

  const logOutHandler = () => {
    showConfirmation(
      MSG.CONFIRM.BUYER.LOG_OUT.TITLE,
      MSG.CONFIRM.BUYER.LOG_OUT.MESSAGE,
      logOutCallback
    )
  }

  return { logOutHandler, logOutCallback }
}
